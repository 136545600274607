import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {kebabCase} from 'lodash'
import {Helmet} from 'react-helmet'
import {graphql, Link, navigate, withPrefix} from 'gatsby'

// import Content, { HTMLContent } from '../components/Content'
import next from '../img/next.svg'
import previous from '../img/previous.svg'
import web from '../img/web.png'
import behance from '../img/behance.svg'
import youtube from '../img/youtube.svg'
import instagram from '../img/instagram.svg'
import flickr from '../img/flickr.svg'
import pinterest from '../img/pinterest.svg'
import tumblr from '../img/tumblr.svg'
import twitter from '../img/twitter.svg'
import linkedin from '../img/linkedin.svg'
import google from '../img/google+.svg'
import facebook from '../img/facebook.svg'
import dribbble from '../img/dribbble.svg'
import cgsociety from '../img/cgsociety.svg'
import reklamFoto from '../img/Digital_Spec_100x700_Artvizit.co.jpg'
import reklamFotoMobil from '../img/Digital_Spec_275x550_Artvizit.co.jpg'
import reklamFotoEn from '../img/Digital_Spec_1000x700_Artvizit.co_eng.jpg'
import reklamFotoMobilEn from '../img/Digital_Spec_275x550_Artvizit.co eng.jpg'
import ReactPlayer from 'react-player'
import Lightbox from 'react-image-lightbox'
import './lightbox.css'

import {createGlobalState} from 'react-hooks-global-state'
import Layout from '../components/Layout'

const initialState = {count: []}
const {useGlobalState} = createGlobalState(initialState)

export const BlogPostTemplate = ({
                                     // content,
                                     // contentComponent,
                                     // description,
                                     // tags,
                                     // title,
                                     helmet,
                                     artvizit,
                                     urls,
                                     items,
                                 }) => {
    const url = typeof window !== 'undefined' ? window.location.pathname : ''
    const [count, setCount] = useGlobalState('count')
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setisOpen] = useState(false)
    const [isVideo, setisVideo] = useState(false)
    const [eser, setEser] = useState(null)
    const [reklam, setReklam] = useState(false)

    let isMobile = false;
    if (typeof window !== 'undefined') {
        isMobile = (window.innerWidth <= 768);
    }

    const onKeyPress = (event) => {
        if (event.keyCode === 27) {
            if (isVideo) {
                setisVideo(false)
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', onKeyPress, false)

        return () => {
            document.removeEventListener('keydown', onKeyPress, false)
        }
    }, [isVideo])

    const injectGA = () => {
        if (typeof window == 'undefined') {
            return
        }
        window.dataLayer = window.dataLayer || []

        function gtag() {
            window.dataLayer.push(arguments)
        }

        gtag('js', new Date())

        gtag('config', 'G-577MST7J0C')
    }

    count.length > 0 ? (urls = count) : (urls = urls)

    if (typeof window !== 'undefined') {
        if (localStorage.getItem('urlCount') == null) {
            localStorage.setItem('urlCount', '0');
        }
    }

    for (var i = urls.length - 1; i >= 0; i--) {
        if (urls[i] === url) {
            urls.splice(i, 1)
        }
    }
    // console.log(97, localStorage.getItem('urlCount'), " count length: ", count.length)

    if (typeof window !== 'undefined') {
        if (localStorage.getItem('urlCount') != null && count.length > 0) {
            let bla = (parseInt(localStorage.getItem('urlCount')) + 1).toString();
            localStorage.setItem('urlCount', bla)
        }
    }
    let artvizitGoruntuleme = 0;
    if (typeof window !== 'undefined') {
        artvizitGoruntuleme = (parseInt(localStorage.getItem('urlCount')))
    }

    if (artvizitGoruntuleme === 3 || artvizitGoruntuleme === 13) {
        //reklam
        // setReklam(!reklam)
    }
    // else if (artvizitGoruntuleme > 20 && artvizitGoruntuleme % 10 == 0) {
    //     //reklam
    //     setReklam(!reklam)
    // }

    // var item = items[Math.floor(Math.random() * items.length)];
    let randomPrev = Math.floor(Math.random() * urls.length)
    let randomNext = (randomPrev + 1) % urls.length
    randomPrev = urls[randomPrev]
    randomNext = urls[randomNext]

    let images = []
    let first = 0,
        second = 0,
        third = 0,
        fourth = 0,
        fifth = 0
    let index = 0
    let counter = 0
    artvizit.eserler.forEach((eser) => {
        if (!eser.video) {
            if (index === 0) {
                first = counter
            } else if (index === 1) {
                second = counter
            } else if (index === 2) {
                third = counter
            } else if (index === 3) {
                fourth = counter
            } else if (index === 4) {
                fifth = counter
            }
            counter++
            images.push(eser)
        }
        index++
    })

    let sosyalMedya = ''
    if (artvizit.sosyalMedya && artvizit.sosyalMedya.length >= 4) {
        if (artvizit.sosyalMedya.includes('instagram')) {
            sosyalMedya = instagram
        } else if (artvizit.sosyalMedya.includes('facebook')) {
            sosyalMedya = facebook
        } else if (artvizit.sosyalMedya.includes('google')) {
            sosyalMedya = google
        } else if (artvizit.sosyalMedya.includes('twitter')) {
            sosyalMedya = twitter
        } else if (artvizit.sosyalMedya.includes('tumblr')) {
            sosyalMedya = tumblr
        } else if (artvizit.sosyalMedya.includes('behance')) {
            sosyalMedya = behance
        } else if (artvizit.sosyalMedya.includes('pinterest')) {
            sosyalMedya = pinterest
        } else if (artvizit.sosyalMedya.includes('flickr')) {
            sosyalMedya = flickr
        } else if (artvizit.sosyalMedya.includes('linkedin')) {
            sosyalMedya = linkedin
        } else if (artvizit.sosyalMedya.includes('dribbble')) {
            sosyalMedya = dribbble
        } else if (artvizit.sosyalMedya.includes('cgsociety')) {
            sosyalMedya = cgsociety
        } else if (artvizit.sosyalMedya.includes('youtube')) {
            sosyalMedya = youtube
        } else {
            sosyalMedya = instagram
        }
    }

    let site = ''
    if (artvizit.site && artvizit.site.length >= 4) {
        if (artvizit.site.includes('instagram')) {
            site = instagram
        } else if (artvizit.site.includes('facebook')) {
            site = facebook
        } else if (artvizit.site.includes('google')) {
            site = google
        } else if (artvizit.site.includes('twitter')) {
            site = twitter
        } else if (artvizit.site.includes('tumblr')) {
            site = tumblr
        } else if (artvizit.site.includes('behance')) {
            site = behance
        } else if (artvizit.site.includes('pinterest')) {
            site = pinterest
        } else if (artvizit.site.includes('flickr')) {
            site = flickr
        } else if (artvizit.site.includes('linkedin')) {
            site = linkedin
        } else if (artvizit.site.includes('dribbble')) {
            site = dribbble
        } else if (artvizit.site.includes('cgsociety')) {
            site = cgsociety
        } else if (artvizit.site.includes('youtube')) {
            site = youtube
        } else {
            site = web
        }
    }

    const getClickableLink = (link) => {
        return link.startsWith('http://') || link.startsWith('https://')
            ? link
            : `http://${link}`
    }

    return (
        <>
            {isVideo ? (
                <div className="full-player">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: '4rem',
                        }}
                    >
                        <div
                            style={{
                                color: 'white',
                                fontSize: '110%',
                                lineHeight: '40px',
                                paddingLeft: '20px',
                            }}
                        >
                            {artvizit.isim + ' | ' + eser.kunye}
                        </div>
                        <button
                            type="button"
                            style={{
                                background:
                                    "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjIgMy41IDM1IDM1Ij4KICA8cGF0aCBkPSJtIDIgNiBsIDIuNSAtMi41IGwgMTUgMTUgbCAxNSAtMTUgbCAyLjUgMi41IGwgLTE1IDE1IGwgMTUgMTUgbCAtMi41IDIuNSBsIC0xNSAtMTUgbCAtMTUgMTUgbCAtMi41IC0yLjUgbCAxNSAtMTUgbCAtMTUgLTE1IHoiIGZpbGw9IiNGRkYiLz4KPC9zdmc+')\n" +
                                    '      no-repeat center',
                                width: '35px',
                                height: '30px',
                                cursor: 'pointer',
                                border: 'none',
                                opacity: '0.9',
                                marginRight: '20px',
                            }}
                            onClick={() => setisVideo(false)}
                        ></button>
                    </div>
                    <div className="full-player-wrapper">
                        <ReactPlayer
                            className="full-react-player"
                            controls={true}
                            playing={true}
                            url={eser.eser}
                            width="100%"
                            height="90%"
                        />
                    </div>
                    <div
                        style={{
                            color: 'white',
                            fontSize: '110%',
                            lineHeight: '40px',
                            paddingLeft: '20px',
                        }}
                    >
                        {eser.aciklama}
                    </div>
                </div>
            ) : (
                <Layout items={items}>
                    <Helmet>
                        <script
                            async
                            src="https://www.googletagmanager.com/gtag/js?id=G-577MST7J0C"
                        />
                        {/* <script type='text/javascript' dangerouslySetInnerHTML={{__html: gaScript}}/> */}
                        <script>{injectGA()}</script>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </Helmet>
                    <div className="tumKonteyner">
                        {helmet || ''}
                        {!reklam ?
                            <div className="rastgeleKonteyner">
                                <div
                                    className="rastgele3"
                                    style={{opacity: '0.4', backgroundImage: `url(${previous})`}}
                                    onClick={() => {
                                        setCount(urls)
                                        navigate(withPrefix(randomPrev))
                                    }}
                                ></div>
                            </div>
                            :
                            <div className="rastgeleKonteyner">
                                <div
                                    className="rastgele3reklam"
                                    style={{opacity: '0.4', backgroundImage: `url(${previous})`}}
                                    onClick={() => {
                                        setCount(urls)
                                        navigate(withPrefix(url))
                                        setReklam(!reklam)
                                    }}
                                ></div>
                            </div>
                        }
                        {!reklam ?
                            <div className="home">
                                <div className="Konteyner">
                                    <div className="about">
                                        <div className="sanatciBeyani">
                                            <div className="sanatciIsmi open-sans-bold">
                                                {' '}
                                                {artvizit.isim}
                                                <div className="baglantiKonteyner">
                                                    {sosyalMedya ? (
                                                        <a
                                                            className="baglanti"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                backgroundSize: 'cover',
                                                                backgroundImage: `url(${sosyalMedya})`,
                                                            }}
                                                            href={getClickableLink(artvizit.sosyalMedya)}
                                                        ></a>
                                                    ) : null}
                                                    {site ? (
                                                        <a
                                                            className="baglanti"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                backgroundSize: 'cover',
                                                                backgroundImage: `url(${site})`,
                                                            }}
                                                            href={getClickableLink(artvizit.site)}
                                                        >
                                                            {' '}
                                                        </a>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="detay open-sans-regular">
                                                {' '}
                                                {artvizit.beyan}{' '}
                                            </div>
                                            {artvizit.etiketler && artvizit.etiketler.length ? (
                                                <div className="etiketKonteyner">
                                                    {artvizit.etiketler.map((etiket, i) => (
                                                        <Link
                                                            key={etiket.etiket}
                                                            className="etiket open-sans-regular"
                                                            to={`/etiket/${kebabCase(etiket.etiket)}/`}
                                                        >
                                                            {etiket.etiket}
                                                        </Link>
                                                    ))}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="eserKonteyner">
                                            {artvizit.eserler[0].video ? (
                                                <div className="player-wrapper">
                                                    <ReactPlayer
                                                        onClickPreview={() => {
                                                            setisVideo(true)
                                                            setEser(artvizit.eserler[0])
                                                        }}
                                                        className="react-player"
                                                        light={true}
                                                        controls={false}
                                                        url={artvizit.eserler[0].eser}
                                                        width="100%"
                                                        height="100%"
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className="eser"
                                                    style={{
                                                        backgroundImage:
                                                            `url("` + `${artvizit.eserler[0].eser}` + `")`,
                                                    }}
                                                    onClick={() => {
                                                        setPhotoIndex(first)
                                                        setisOpen(true)
                                                    }}
                                                />
                                            )}
                                            {artvizit.eserler[1].video ? (
                                                <div className="player-wrapper">
                                                    <ReactPlayer
                                                        onClickPreview={() => {
                                                            setisVideo(true)
                                                            setEser(artvizit.eserler[1])
                                                        }}
                                                        className="react-player"
                                                        light={true}
                                                        controls={false}
                                                        url={artvizit.eserler[1].eser}
                                                        width="100%"
                                                        height="100%"
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className="eser"
                                                    style={{
                                                        backgroundImage:
                                                            `url("` + `${artvizit.eserler[1].eser}` + `")`,
                                                    }}
                                                    onClick={() => {
                                                        setPhotoIndex(second)
                                                        setisOpen(true)
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="altKonteyner">
                                        {artvizit.eserler[2].video ? (
                                            <div className="player-wrapper">
                                                <ReactPlayer
                                                    onClickPreview={() => {
                                                        setisVideo(true)
                                                        setEser(artvizit.eserler[2])
                                                    }}
                                                    className="react-player"
                                                    light={true}
                                                    controls={false}
                                                    url={artvizit.eserler[2].eser}
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="altEser"
                                                style={{
                                                    backgroundImage:
                                                        `url("` + `${artvizit.eserler[2].eser}` + `")`,
                                                }}
                                                onClick={() => {
                                                    setPhotoIndex(third)
                                                    setisOpen(true)
                                                }}
                                            />
                                        )}
                                        {artvizit.eserler[3].video ? (
                                            <div className="player-wrapper">
                                                <ReactPlayer
                                                    onClickPreview={() => {
                                                        setisVideo(true)
                                                        setEser(artvizit.eserler[3])
                                                    }}
                                                    className="react-player"
                                                    light={true}
                                                    controls={false}
                                                    url={artvizit.eserler[3].eser}
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="altEser"
                                                style={{
                                                    backgroundImage:
                                                        `url("` + `${artvizit.eserler[3].eser}` + `")`,
                                                }}
                                                onClick={() => {
                                                    setPhotoIndex(fourth)
                                                    setisOpen(true)
                                                }}
                                            />
                                        )}
                                        {artvizit.eserler[4].video ? (
                                            <div className="player-wrapper">
                                                <ReactPlayer
                                                    onClickPreview={() => {
                                                        setisVideo(true)
                                                        setEser(artvizit.eserler[4])
                                                    }}
                                                    className="react-player"
                                                    light={true}
                                                    controls={false}
                                                    url={artvizit.eserler[4].eser}
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="altEser"
                                                style={{
                                                    backgroundImage:
                                                        `url("` + `${artvizit.eserler[4].eser}` + `")`,
                                                }}
                                                onClick={() => {
                                                    setPhotoIndex(fifth)
                                                    setisOpen(true)
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={images[photoIndex].eser}
                                        imageTitle={artvizit.isim + ' | ' + images[photoIndex].kunye}
                                        imageCaption={images[photoIndex].aciklama}
                                        nextSrc={images[(photoIndex + 1) % images.length].eser}
                                        prevSrc={
                                            images[(photoIndex + images.length - 1) % images.length]
                                                .eser
                                        }
                                        onCloseRequest={() => {
                                            setisOpen(false)
                                        }}
                                        imagePadding={50}
                                        onMovePrevRequest={() =>
                                            setPhotoIndex(
                                                (photoIndex + images.length - 1) % images.length
                                            )
                                        }
                                        onMoveNextRequest={() =>
                                            setPhotoIndex((photoIndex + 1) % images.length)
                                        }
                                    />
                                )}
                            </div> :
                            <>
                            {window.location.href.includes("/en/") ? (
                                <div>
                                    {isMobile ?
                                        (<div className="homereklam">
                                            <a target="_blank" href="https://gdetr.hit.gemius.pl/hitredir/id=cohAfQd4qwDnS5d4cITklcRp.jf_868j39GNw8PFYIn.V7/fastid=gwlwgjdbsdbhsudtrtxgaxcltslv/stparam=rkmijpiuov/nc=0/gdpr=0/gdpr_consent=/url=https://igart.istanbul/en/contests/d1-check-in-area.html?utm_medium=Artvizit&utm_content=Mobil-Reklam-Eng&utm_campaign=IGA-Igart-Sanat-Yarismalari-Subat-2023" style={{width:'275px', height:'550px', zIndex:'5'}}>
                                                <img src={reklamFotoMobilEn} style={{maxWidth: '100%'}}/>
                                            </a>
                                            <img src="https://gdetr.hit.gemius.pl/_[TIMESTAMP]/redot.gif?id=cohAfQd4qwDnS5d4cITklcRp.jf_868j39GNw8PFYIn.V7/fastid=cozcolegamxcfkergzmctpisvslj/stparam=xiolcveljf/nc=0/gdpr=0/gdpr_consent=" />
                                        </div>)
                                        :
                                        (<div className="homereklam">
                                            <a target="_blank" href="https://gdetr.hit.gemius.pl/hitredir/id=cohAfQd4qwDnS5d4cITklcRp.jf_868j39GNw8PFYIn.V7/fastid=mwqvtdnkmkazazbnofubcthrjdln/stparam=ymrldxfsck/nc=0/gdpr=0/gdpr_consent=/url=https://igart.istanbul/en/contests/d1-check-in-area.html?utm_medium=Artvizit&utm_content=Web-Reklam-Eng&utm_campaign=IGA-Igart-Sanat-Yarismalari-Subat-2023" style={{width:'100%', height:'100%', zIndex:'5'}}>
                                                <img src={reklamFotoEn} style={{maxWidth: '100%'}}/>
                                            </a>
                                            <img src="https://gdetr.hit.gemius.pl/_[TIMESTAMP]/redot.gif?id=cohAfQd4qwDnS5d4cITklcRp.jf_868j39GNw8PFYIn.V7/fastid=khfvmvzcrnumvndgfccnplnyldlv/stparam=oldpmmsiiu/nc=0/gdpr=0/gdpr_consent=" />
                                        </div>)}
                                </div>
                            ) :
                                <div>
                                    {isMobile ?
                                        (<div className="homereklam">
                                            <a target="_blank" href="https://gdetr.hit.gemius.pl/hitredir/id=cohAfQd4qwDnS5d4cITklcRp.jf_868j39GNw8PFYIn.V7/fastid=awgxqyhniasweniwxnoybesaownb/stparam=tfcseqniav/nc=0/gdpr=0/gdpr_consent=/url=https://igart.istanbul/yarismalar/d1-check-in-bolgesi.html?utm_medium=Artvizit&utm_content=Mobil-Reklam-Tr&utm_campaign=IGA-Igart-Sanat-Yarismalari-Subat-2023" style={{width:'275px', height:'550px', zIndex:'5'}}>
                                                <img src={reklamFotoMobil} style={{maxWidth: '100%'}}/>
                                            </a>
                                            <img src="https://gdetr.hit.gemius.pl/_[TIMESTAMP]/redot.gif?id=cohAfQd4qwDnS5d4cITklcRp.jf_868j39GNw8PFYIn.V7/fastid=noaxczpiiwhieoelbrshqwyhqwmq/stparam=zdefovjplb/nc=0/gdpr=0/gdpr_consent=" />
                                        </div>)
                                        :
                                        (<div className="homereklam">
                                            {/*<img src={reklamFoto} style={{maxWidth: '100%'}}/>*/}
                                            <a target="_blank" href="https://gdetr.hit.gemius.pl/hitredir/id=cohAfQd4qwDnS5d4cITklcRp.jf_868j39GNw8PFYIn.V7/fastid=mqsyotndncobkybturnezaxgehmk/stparam=kihfpxkgzk/nc=0/gdpr=0/gdpr_consent=/url=https://igart.istanbul/yarismalar/d1-check-in-bolgesi.html?utm_medium=Artvizit&utm_content=Web-Reklam-Tr&utm_campaign=IGA-Igart-Sanat-Yarismalari-Subat-2023" style={{width:'100%', height:'100%', zIndex:'5'}}>
                                                <img src={reklamFoto} style={{maxWidth: '100%'}}/>
                                            </a>
                                            <img src="https://gdetr.hit.gemius.pl/_[TIMESTAMP]/redot.gif?id=cohAfQd4qwDnS5d4cITklcRp.jf_868j39GNw8PFYIn.V7/fastid=didbyivacuwhfrgcxxwxdtdnghlf/stparam=qgjiinhnvq/nc=0/gdpr=0/gdpr_consent=" />
                                        </div>)
                                    }
                                </div>
                        }
                        </>
                        }

                        {!reklam ?
                            <div className="rastgeleKonteyner">
                                <div
                                    className="rastgele3"
                                    style={{opacity: '0.4', backgroundImage: `url(${next})`}}
                                    onClick={() => {
                                        setCount(urls)
                                        navigate(withPrefix(randomNext))
                                    }}
                                ></div>
                            </div>
                            :
                            <div className="rastgeleKonteyner">
                                <div
                                    className="rastgele3reklam"
                                    style={{opacity: '0.4', backgroundImage: `url(${next})`}}
                                    onClick={() => {
                                        setCount(urls)
                                        navigate(withPrefix(url))
                                        setReklam(!reklam)
                                    }}
                                ></div>
                            </div>}
                    </div>
                </Layout>
            )}
        </>
    )
}

BlogPostTemplate.propTypes = {
    // content: PropTypes.node.isRequired,
    // contentComponent: PropTypes.func,
    // description: PropTypes.string,
    // title: PropTypes.string,
    helmet: PropTypes.object,
    artvizit: PropTypes.object,
    pageContext: PropTypes.object,
}

const BlogPost = ({data, pageContext}) => {
    const {markdownRemark: post} = data
    const {urls, items} = pageContext

    return (
        <BlogPostTemplate
            // content={post.html}
            // contentComponent={HTMLContent}
            // description={post.frontmatter.description}
            helmet={
                <Helmet titleTemplate="%s | Artvizit">
                    <title>{`${post.frontmatter.isim}`}</title>
                    <meta
                        name="description"
                        // content={`${post.frontmatter.description}`}
                    />
                    <base href="/"/>
                </Helmet>
            }
            artvizit={post.frontmatter}
            urls={urls}
            items={items}
        />
    )
}

BlogPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
        pageContext: PropTypes.object,
    }),
}

export default BlogPost

export const pageQuery = graphql`
    query BlogPostByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                tarih(formatString: "MMMM DD, YYYY")
                isim
                beyan
                etiketler {
                    etiket
                }
                eserler {
                    eser
                    video
                    kunye
                    aciklama
                }
                sosyalMedya
                site
            }
        }
    }
`
